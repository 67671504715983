.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #262f33;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  padding: 80px 0;
}

.div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 40px 0;
}
.div button {
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 115% */
}
.div p {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 115% */
  margin: 40px 0px;
  text-align: left;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
    align-items: center;
  }
  .container > [class^="UnderlineHeader"] {
    justify-content: center !important;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 40px 0;
  }
}