.cookieContainer {
    position: fixed;
    right: 12px;
    bottom: 12px;
    display: block;
    z-index: 3;
    width: 500px;
    background-color: #333333;
    box-shadow: 4px 4px 10px 0 rgba(20,24,26,0.75);
    -webkit-box-shadow: 4px 4px 10px 0 rgba(20,24,26,0.75);
    -moz-box-shadow: 4px 4px 10px 0 rgba(20,24,26,0.75);;
}

.cookieContent {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 16px;
}
.cookieContent > h2 {
    margin: 0 0 12px 0;
}

.text {
    font-size: 15px !important;
    margin-bottom: 0;
}

.buttonContainer {
    margin-top: 24px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
}

.privacyContainer {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.hyperlink {
    color: white;
}

.button {
    position: relative;
    width: 200px !important;
    height: 100%;
    color: black;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}
.button::after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 0 0 16px 16px;
    border-color: transparent transparent #333333;
    bottom: 0;
    right: 0;
}

@media only screen and (max-width: 560px) {
    .cookieContainer {
        width: 320px;
        left: calc(50vw - 166px);
        bottom: calc(50vh - 180px);
    }

    .privacyContainer {
        flex-direction: column;
    }
}