.card {
  width: 100%;
  min-width: 260px;
  flex: 320px 0 1;
  height: 400px;
  border-radius: 20px;
  background: #f0f0f0;
  box-shadow: 4px 4px 10px 0 rgba(20,24,26,0.75);
  -webkit-box-shadow: 4px 4px 10px 0 rgba(20,24,26,0.75);
  -moz-box-shadow: 4px 4px 10px 0 rgba(20,24,26,0.75);;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.icon {
  width: 157px;
  height: 157px;
  flex-shrink: 0;
}

.p{
    width: 80%;
    color: #171717;
}

@media only screen and (max-width: 900px) {
  .card {
    flex-direction: column;
    min-width: 240px;
    flex: 240px 1 1;
  }

  .icon {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
  }

  .p {
    font-size: 16px !important;
  }
}