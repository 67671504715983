.scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #e1251b;
    color: white;
    border: none;
    border-radius: 40px;
    width: 60px;
    height: 60px;
    font-size: 18px;
    cursor: pointer;
    display: none;
    z-index: 2;
    box-shadow: 3px 3px 6px 0 rgba(63, 63, 63, 0.46);
    -webkit-box-shadow: 3px 3px 6px 0 rgba(63, 63, 63, 0.46);
    -moz-box-shadow: 3px 3px 6px 0 rgba(63, 63, 63, 0.46);
}

.show {
    display: block;
}

.hide {
    display: none;
}