.container_odd {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 220px;
}

.img_column_odd {
  display: flex;
  padding-left: 40px;
  flex: 1;
  width: 100%;
  justify-content: center;
}

.img_container_odd {
  max-width: 400px;
  max-height: 220px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.content_odd {
  display: flex;
  padding-right: 40px;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  flex: 1;
  width: 100%;
}
/******************************/
.container_even {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: black;
  height: 240px;
}

.img_column_even {
  display: flex;
  padding-right: 40px;
  flex: 1;
  width: 100%;
  justify-content: flex-start;
}

.img_container_even {
  max-width: 400px;
  max-height: 220px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}

.content_even {
  display: flex;
  padding-left: 40px;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  flex: 1;
  width: 100%;
}
/******************************/
.container_odd::before,
.container_even::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #e1251b;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.content_even > h1,
.content_odd > h1,
.text {
  color: black;
}

.content_even > h1,
.content_odd > h1 {
  font-size: 32px;
}

.img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.text {
  display: flex;
  width: 100%;
}

.quartile_header {
}
/******************************/

@media only screen and (max-width: 900px) {
  .img_column_odd {
    padding-right: 20px;
  }
  .img_column_even {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 550px) {
 .container_even,
 .container_odd {
   flex-direction: column;
   max-height: 500px;
   height: 100%;
   justify-content: center;
   border-bottom: 1px dashed #262f33;
   padding-bottom: 10px;
 }
  .img_container_odd,
  .img_container_even {
    justify-content: center;
  }
  .img_column_even,
  .img_column_odd,
  .content_even,
  .content_odd {
    padding: 0;
    margin: 12px 0;
  }
  .container_odd::before,
  .container_even::before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #e1251b;
    border-radius: 10px;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%);
  }
  .img_column_even,
  .img_column_odd {
    justify-content: center;
  }
  .quartile_header {
    margin: 0 0 13px 0;
  }
  .text {
    margin: 8px 0;
  }
}