.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  width: 60%;
  padding: 80px 0;
}

.container p {
  color: #171717;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px; /* 115% */
  text-align: center;
  margin-top: 20px;
}

.mailLink {
  color: #171717;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
}
.contacts{
    width: 100%;
}

@media only screen and (max-width: 550px) {
    .container {
        width: 90%;
        padding: 40px 0;
    }
}
