.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.container {
  width: 60%;
  padding: 80px 0;
}

.newsCards {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-gap: 80px;
  margin-top: 40px;
  padding-top: 0;
}
.wrapper::before {
  content: "";
  left: 50%;
  top: 30px;
  border-left: 1px dashed #cacaca;
  height: calc(100% - 60px);
  width: 1px;
  position: absolute;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
  }
}

@media only screen and (max-width: 624px) {
  .newsCards {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 40px 0;
  }
  .wrapper::before {
    display: none;
  }
  .newsCards {
    grid-gap: 40px;
    padding-top: 0;
  }
  .newsCards [class^="NewsCard_container"]:first-child::before {
    display: none;
  }
}
