.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #262f33;
}

.wrapper p {
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 115% */
  text-align: left;
}
.container {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
}

.split {
  display: flex;
  align-content: flex-start;
}

.left {
  flex:1 1 auto;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 350px;
  flex-shrink: 0;
}

.img_container {
  display: flex;
  max-width: 25em;
  max-height: 20em;
  border-radius: 20px;
  overflow: hidden;
  width: auto;
  height: auto;
  padding: 24px;
}

.img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

.gelsoLink {
  text-decoration: underline;
}
.gelsoLink:hover{
  color: dodgerblue;
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
  }
  .split {
    flex-direction: column;
  }
  .right {
    display: flex;
    flex: 1;
    flex-direction: row;
    max-height: 290px;
    margin-top: 24px;
  }
  .left {
    width: 100%;
  }
  .img_container {
    display: inline-block;
  }
  .img_container,
  .img {
    flex-shrink: 1;
    max-height: 290px;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 40px 0;
  }
  .right {
    flex-direction: column;
    max-height: unset;
  }
  .img_container {
    padding: 12px;
  }
  .img_container,
  .img {
    max-width: 360px;
  }
}

@media only screen and (max-width: 420px) {
  .img,
  .img_container {
    max-width: 290px;
  }
}

@media only screen and (max-width: 320px) {
  .img,
  .img_container {
    max-width: 250px;
  }
}
