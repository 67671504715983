header {
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
  height: 67px;
  background: #14181a;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  z-index: 99;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-left: 20px;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  color: #fff;
  font-family: Montserrat;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: 12.234px; /* 67.967% */
}

.navButton {
  width: 146px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #e1251b;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-left: 30px;
  line-height: 12.234px; /* 101.95% */
  border: none;
}

.buttonsLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
}

.select {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  height: 25px;
  width: 50px;
  margin-left: 5px;
}

.langButtons {
  position: absolute;
  margin-top: 100px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.langButtons button {
  color: #14181a;
  margin-top: 2px;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  border: #14181a solid 1px;
}

a, button {
  color: white;
}

@media only screen and (max-width: 1200px) {
  header {
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    margin-left: 0;
    margin-top: 20px;
  }
  .buttonsLeft {
    margin-bottom: 50px;
  }
  .langButtons {
    margin-top: 0;
    margin-bottom: 110px;
  }
  .links {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
  }
  .hidden {
    display: none;
  }
  .hiddenHeader {
    height: 50px;
  }
}
