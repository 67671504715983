.wrapper {
  background: #262f33;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.readButton {
  margin-top: 20px;
  width: 268px;
  height: 49px;
  border-radius: 10px;
  background: #e1251b;
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 115% */
}
.recommendContainer,
.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
  padding: 80px 0;
}

.recommendContainer {
  min-height: calc(100vh - 416px);
}
.recommendContainer > .middleSplit > .left > p {
  color: black;
}

.middleSplit {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.middleSplit p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px; /* 115% */
  text-align: left;
  margin-top: 40px;
}
.left {
  position: relative;
  margin-right: 60px;
}

.img {
  border-radius: 20px;
}

.searchInput {
  width: 500px;
  max-width: 90%;
  height: 50px;
  border-radius: 10px;
  border: none;
  background: #d0d0d0;
  outline: none;
  color: #171717;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 115% */
  text-indent: 12px;
  margin-bottom: 0;
}
.searchInput::placeholder {
  text-indent: 2px;
}

.headerFaq {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: #e1251b solid 2.5px;
}

.headerFaq h2 {
  max-width: 100%;
  margin: 10px;
  color: #171717;
  text-align: left;
  font-family: Montserrat;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.faqCards{
  max-width: 100%;
}
.title {
  white-space: nowrap;
  margin-bottom: 0;
}

.empty {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  flex: 1;
  margin-top: 40px;
}

.empty > p {
  color: #444444;
  font-size: 24px !important;
}

.loading {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(203, 203, 203, 0.5);
  justify-content: center;
  align-items: center;
  transition: opacity 300ms;
  top: 0;
  left: 0;
}

.show {
  opacity: 1;
}
.hide {
  opacity: 0;
}

.spin {
  position: absolute;
  top: min(100px, calc(50% - 32px));
  left: calc(50% - 32px);
  animation: rotate 1s linear infinite;
}

.subTitle {
  color: black;
  font-size: 24px;
  margin: 24px 0 40px 0 !important;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
  }
  .recommendContainer {
    width: 90%;
    min-height: calc(100vh - 439px);
  }
  .middleSplit {
    flex-direction: row;
  }
  .headerFaq {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .searchInput {
    max-width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .recommendContainer {
    min-height: calc(100vh - 468px);
  }
  .headerFaq {
    flex-direction: column;
  }
  .left {
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .middleSplit {
    flex-direction: column;
  }
  .recommendContainer {
    min-height: calc(100vh - 468px);
  }
}

@media only screen and (max-width: 675px) {
  .recommendContainer {
    min-height: calc(100vh - 489px);
  }
}

@media only screen and (max-width: 670px) {
  .recommendContainer {
    min-height: calc(100vh - 656px);
  }
}

@media only screen and (max-width: 530px) {
  .container {
    padding: 40px 0;
  }
  .left {
    margin-right: 0;
    margin-bottom: 24px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg); /* You can adjust the degree to control the rotation amount */
  }
}

