.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: 60%;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wrapper p {
  color: #171717;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px; /* 115% */
}
.table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
}
.table div {
  width: 100%;
}
.table div div {
  color: #171717;
  /*border: #171717 solid 1.25px;*/
}

.leftRow div:first-child {
  font-weight: 600;
  border-top-left-radius: 10px;
}
.leftRow div:last-child {
  border-bottom-left-radius: 10px;
}
.rightRow div:first-child {
  font-weight: 600;
  border-top-right-radius: 10px;
}
.rightRow div:last-child {
  border-bottom-right-radius: 10px;
}

.rightRow div,
.leftRow div {
  background-color: #fcfcfc;
}
.leftRow {
  border-right: 1px solid #d9d9d9;
}
.rightRow div:nth-child(odd),
.leftRow div:nth-child(odd) {
  background-color: #f4f4f4;
  border-top: 1px solid rgba(38, 47, 51, 0.18);
  border-bottom: 1px solid rgba(38, 47, 51, 0.18);
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bottomImg {
  max-width: 100%;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
    align-items: center;
  }
  .container [class^="Underline"] {
    justify-content: center !important;
  }

  .bottom{
    flex-direction: column;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 40px 0;
  }
  .leftRow,
  .rightRow {
    font-size: clamp(0.75rem, 0.45rem + 1.6vw, 1rem);
  }
  p {
    font-size: 14px;
  }
}

