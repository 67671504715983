.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container,
.faqPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  padding: 80px 0;
}

.loading {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(203, 203, 203, 0.5);
  justify-content: center;
  align-items: center;
  transition: opacity 300ms;
  top: 0;
  left: 0;
}

.show {
  opacity: 1;
}
.hide {
  opacity: 0;
}

.spin {
  position: absolute;
  top: min(100px, calc(50% - 32px));
  left: calc(50% - 32px);
  animation: rotate 1s linear infinite;
}

.faqPageContainer {
  min-height: calc(100vh - 416px);
}

.headerParagraph {
  color: #171717;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px; /* 115% */
  margin-top: 40px;
  text-align: left;
}
::placeholder {
  padding: 10px;
}

.headerMail,
.headerParagraph p {
  color: #171717;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  text-decoration: none;
}
.readButton {
  width: 268px;
  height: 49px;
  border-radius: 10px;
  background: #364146;
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 115% */
  margin-top: 40px;
}
.searchInput {
  width: 488px;
  max-width: 90%;
  height: 50px;
  border-radius: 10px;
  border: none;
  background: #d0d0d0;
  outline: none;
  color: #171717;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 115% */
  text-indent: 12px;
}
.searchInput::placeholder {
  text-indent: 2px;
}

.headerFaq {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: #e1251b solid 2.5px;
  max-width: 100%;
}

.headerFaq h2 {
  margin: 10px;
  color: #171717;
  text-align: left;
  font-family: Montserrat;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.faqCards{
  position: relative;
  width: 100%;
  max-width: 100%;
}

.empty {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  flex: 1;
}

.empty > p {
  color: #444444;
  font-size: 24px !important;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
  }
  .faqPageContainer {
    width: 90%;
    min-height: calc(100vh - 439px);
  }
}

@media only screen and (max-width: 900px) {
  .faqPageContainer {
    min-height: calc(100vh - 468px);
  }
}

@media only screen and (max-width: 680px) {
  .faqPageContainer {
    min-height: calc(100vh - 489px);
  }
}

@media only screen and (max-width: 670px) {
  .faqPageContainer {
    min-height: calc(100vh - 656px);
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
  .headerFaq {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .searchInput {
    max-width: 90%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 40px 0;
  }
  .headerMail {
    font-size: 16px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg); /* You can adjust the degree to control the rotation amount */
  }
}
