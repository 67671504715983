.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  padding: 80px 0;
}
.wrapper p {
  color: #171717;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 115% */
  text-align: left;
}
.middleSplit{
 display: flex;
 justify-content: space-around;
 margin-bottom: 40px;
}
.img{
    margin-top: 40px;
}
.wrapper h3 {
  color: #171717;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  margin: 40px 0px;
}
.middleSplit > .left{
  padding-right: 24px;
}

.img{
  max-width: 100%;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
    overflow: hidden;
    align-items: center;
  }
  .container [class^="Underline"] {
    justify-content: center !important;
  }
  .middleSplit{
    flex-direction: column;
  }
  .middleSplit > .left{
    padding-right: 0;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 40px 0;
  }
}
