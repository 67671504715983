.underline{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: #E1251B solid 2.5px;

}
.text{
    max-width: 100%;
    overflow-wrap: break-word;
    text-align: left;
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 0;
}

@media only screen and (max-width: 600px) {
    .underline{
        width: 100% !important;
        justify-content: center !important;
    }
}