.tipContainer {
    display: grid;
    grid-template-columns: 5fr 3fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: 24px 0;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-area: 1 / 1 / 2 / 2;
}
.textContainer > p,
.textContainer > h2 {
    color: #000;
    text-align: start;
    margin: 0;
}
.labelNumber {
    color: #e1251b;
    margin-right: 12px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    /*margin-top: 24px;*/
    margin-left: 24px;
    grid-area: 1 / 2 / 2 / 3;
    max-width: 324px;
}

.img {
    max-height: 300px;
    max-width: 100%;
    border-radius: 20px;
}

@media only screen and (max-width: 900px) {
    .tipContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .textContainer {
        width: 100%;
    }
    .imageContainer {
        margin: 24px 24px 0 24px;
    }
}