.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #262f33;
}
.container {
  width: 60%;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.p {
  color: white;
  text-align: left;
  margin: 40px 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
.cards {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  gap: 10px;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
    align-items: center;
  }
  .container [class^="Underline"] {
    justify-content: center !important;
  }
  .cards {
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    width: 60%;
  }
  .container [class^="Underline"] {
    min-width: 220px;
    text-align: center;
  }
  .cards {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    width: 90%;
    padding: 40px 0;
  }
}