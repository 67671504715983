.faqCard {
    display: inline-table;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px dashed black;
    max-width: 100%;
    width: 100%;
}

.p {
    color: #171717;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px; /* 115% */
    text-align: left;
    margin: 0;
}

.l {
    color: #171717;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px; /* 115% */
    text-align: left;
}

.question {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    width: 100%;
}

.answerText {
    display: flex;
    align-items: flex-start;
}

.answer {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

.q {
    color: #e1251b;
}

.a {
    color: #14181a;
}

.q, .a {
    font-size: 32px;
    font-weight: 600;
    margin-inline: 12px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    margin: 24px 0 0 24px;
}

.img {
    max-height: 400px;
    max-width: 100%;
    border-radius: 20px;
}

.card {

}

.index {
    display: table-cell;
    background: linear-gradient(to bottom, #e1e1e1, #fff);
    color: black;
    font-size: 32px;
    font-weight: 600;
    height: 100%;
    width: 28px;
    padding-inline: 12px;
}

@media only screen and (max-width: 850px) {
    .faqCard .left {
        width: 100%;
    }
}

@media only screen and (max-width: 550px) {
    .faqCard {
        margin: 24px 0;
    }

    .faqCard .left {
        margin-right: 0;
    }
}