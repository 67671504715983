.button {
  color: white;
  width: 268px;
  height: 49px;
  flex-shrink: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 115% */
  border-radius: 10px;
  background: #e1251b;
}
