.section {
  background: #262f33;
  box-shadow: 26px 0px 40px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  height: auto;
  min-height: 100vh;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(to left, #14181a, #14181a, #262f33);
}
.right {
  min-width: 61.54%;
  height: 100vh;
  overflow: hidden;
}
.left {
  z-index: 2;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height: 100%;
  margin-left: 50px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h1 {
  color: white;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-bottom: 40px;
}

.p1{
  color: white;
  margin-bottom: 32px;
  text-align: left;
  align-self: start;
  font-weight: 500;
}

.ul {
  margin-bottom: 70px;
}

.li {
  color: white;
  margin-bottom: 20px;
  font-weight: 500;
}

.disclaimer {
  color: white;
  margin-top: 25px;
  font-weight: 500;
}

.video {
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  height: 100vh;
  min-height: 100%;
  min-width: 100%;
  left: -150px;
  margin: 0;
  max-height: none;
  max-width: none;
  object-fit: cover;
  outline: none;
  padding: 0;
  position: relative;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1350px) {
  .section{
    background: rgba(0, 0, 0, 0.25);
  }
  .container{
    position: relative;
    overflow: hidden;
    background: none;
  }
  .left{
    margin: 30px 0;
    width: 90%;
  }
  .ul {
    margin-bottom: 0px;
  }
  .right{
    width: 100%;
    height: 100%;
    top:40px;
    right: 0;
    bottom: 0;
    position: absolute;
    display: inline-block;

  }
  .video{
    position: relative;
    top:0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    max-height: none;
    max-width: none;
    outline: none;
    z-index: -1;

  }
}

@media only screen and (max-width: 1400px) {
  .right {
    background-color: rgba(0, 0, 0, 0.40);
  }
}

@media only screen and (max-width: 580px) {
    .h1 {
      font-size: clamp(1.5rem, -0.2411rem + 9.2857vw, 3.125rem);
    }
}

@media only screen and (max-width: 400px) {
  .p{
    font-size: 0.5em;
  }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}