.App {
  text-align: center;
  max-width: 100%;
}
* {
  /*color: #fff;*/
  font-family: Montserrat;
  font-style: normal;
}

button {
  border: none;
}

section {
  width: 100%;
  box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.2);
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

@media only screen and (min-width: 550px) {
  p {
    font-size: 1em !important;
  }
}

@media only screen and (max-width: 550px) {
  * {
    text-size-adjust: auto;
  }
}
