.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.container {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}
.itemCards {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}
.explainer {
  border-radius: 20px;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  width: 100%;
}
.equalizerImg {
  width: 157px;
  height: 157px;
}

.explainerP {
  text-align: left;
  color: #171717;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px; /* 115% */
  margin: 30px 30px 30px 0;
}

.quoteExplainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p {
  color: #171717;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px; /* 115% */
  margin-top: 40px;
  margin-bottom: 40px;
}
.leftP {
  text-align: left;
}
b {
  color: #171717;
}
.img{
  max-width: 535px;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
  }
}

@media only screen and (max-width: 1200px) {
  .quoteExplainer {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .explainer{
    flex-direction: column;
  }
  .explainerP {
    margin: 0 30px 30px 30px;
  }
  .img{
    max-height: 300px;
    max-width: 400px;
  }
}

@media only screen and (max-width: 955px) {
  .itemCards {
    flex-direction: column;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 40px 0;
    font-size: 14px;
  }
  .explainerP,
  p {
    font-size: 14px !important;
  }
}
