.wrapper {
  background: linear-gradient(to bottom, #262f33, #14181a);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 60%;
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signUp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

h2, p {
  color: white;
}

.signUpArea {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.input {
  border-radius: 10px;
  background: #fbfbfb;
  max-width: 300px;
  width: 100%;
  outline: none;
  text-indent: 10px;
  caret-color: black;
  height: 34px;
  color: black;
}
.input::placeholder {
  color: #676767;
  text-indent: 0;
}

.mail {
  display: flex;
}
.subButton {
  margin-left: 20px;
  width: 195px;
  height: 40px;
  border-radius: 10px;
  transition: background-color 0.3s ease-in;
  background: #e1251b;
  font-size: 16px;
  position: relative;
  display: inline-block;
}
.subButton:disabled {
  cursor: not-allowed;
  transition: background-color 0.3s ease-in;
  background: #8c8c8c;
}
.tooltipText {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  font-size: 14px;
  z-index: 1;
}
.subButton:hover .tooltipText {
  visibility: visible;
}
.tooltipText {
  width: 200px;
  top: calc(100% + 10px);
  left: 50%;
  margin-left: -100px;
}
.subButton .tooltipText::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.subButton .tooltipText {
  opacity: 0;
  transition: opacity 0.4s;
}
.subButton:hover .tooltipText {
  opacity: 1;
}

.info {
  display: flex;
  flex-direction: column;

}
.info img {
  width: 124px;
  height: 26px;
  margin-left: 10px;
  flex-shrink: 0;
}

.logo {
  display: none;
}

.infoContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

.infoPart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 64px;
}

.infoData {
  color: white;
  display: flex;
  margin-left: 12px;
  flex-direction: column;
  align-items: flex-start;
}

.infoData > p {
  margin: 2px 0;
}

.divider {
  border-left: 1px solid #626262;
  overflow: hidden;
  margin: 0 24px;
}

.dividerVert {
  border-bottom: 1px dotted #626262;
  margin: 24px 0;
  width: calc(100% - 32px);
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.modalContent {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}
.modalContent > h2 {
  margin-bottom: 24px;
  margin-top: 0;
}
.modalContent > p,
.modalContent > h2 {
  color: black;
  text-align: start;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
    justify-content: center;
  }
  .info {
    width: 350px;
  }
  .signUp {
    flex-direction: column;
    width: 350px;
  }
  .mail {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .subButton {
    margin-left: 0;
  }
  .infoP {
    flex-direction: column;
  }
}

@media only screen and (max-width: 900px) {
  .info {
    width: 320px;
  }
  .signUp {
    flex-direction: column;
    width: 320px;
    align-items: flex-start;
  }
  h2 {
    text-align: start;
  }
}

@media only screen and (max-width: 670px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .signUp {
    align-items: center;
    width: 100%;
  }
  .mail {
    align-items: center;
    width: 100%;
  }
  .divider {
    width: 100%;
    max-width: 400px;
    border-bottom: 1px solid #626262;
    overflow: hidden;
    margin: 12px 0;
  }
  .info {
    flex-direction: row;
  }
  .infoContainer {
    align-items: center;
  }
}

@media only screen and (max-width: 1400px) {
  .mail {
    align-items: center;
  }
}

@media only screen and (max-width: 360px) {
  .info {
    width: 280px;
  }
  .signUp {
    flex-direction: column;
    width: 280px;
  }
}