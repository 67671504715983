.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 60%;
  height: 100%;
  padding: 80px 0px;
}

.list {
  text-align: left;
  margin-top: 60px;
  margin-bottom: 0;
  align-self: flex-end;
  align-items: end;
}

.list li {
  color: #171717;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}
.right {
  align-items: end;
  align-self: flex-end;
  margin-left: 20px;
}

.video {
  border-radius: 20px;
  max-width: 100%;
  box-shadow: 4px 4px 10px 0 rgba(20,24,26,0.75);
  -webkit-box-shadow: 4px 4px 10px 0 rgba(20,24,26,0.75);
  -moz-box-shadow: 4px 4px 10px 0 rgba(20,24,26,0.75);
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 90%;
    flex-direction: row;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    width: 90%;
    flex-direction: column;
  }
  .list {
    margin-top: 40px;
  }
  .right {
    margin-top: 24px;
    align-self: center;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 40px 0;
  }
  .list li {
    font-size: 16px;
  }
}

