.label {
  color: #171717;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 95.833% */
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 12px 0;
}
.picture {
  width: 20em;
  max-width: 100%;
  margin: 12px 0;
}
.desc{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  text-align: left;
}
.p {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  color: #171717;
  margin: 2px 0;
  max-width: 450px;
}
.p > span {
  /*width: 50%;*/
  color: black;
  font-size: 16px;
}
.b {
  padding: 0;
  margin: 0;
  color: #171717;
  font-weight: 600;
  font-size: 16px;
  text-align: start;
}
.card {
  border-radius: 20px;
  border: 1px solid #eeeeee;
  padding: 16px;
  flex: 1;
  background-color: #ffffff;
}

@media only screen and (max-width: 1200px) {
  .p {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1200px) {
  .p > span,
  .b {
    font-size: clamp(0.625rem, 0.0329rem + 3.1579vw, 1rem);
    line-height: clamp(0.5rem, -2.0625rem + 10vw, 1rem);
  }
}

@media only screen and (max-width: 330px) {
  .card {
    padding: 10px;
  }
}